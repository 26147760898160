body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.gtco-testimonials .card .card-img-top {
  max-width: 100px;
  border-radius: 50%;
  margin: 15px auto 0;
  box-shadow: 0 8px 20px -4px #95abbb;
  width: 50px;
  height: 50px;
}

.gtco-testimonials .card {
  background: #fff;
  box-shadow: 0 8px 30px -7px #c9dff0;
  height: 25vw;
  object-fit: cover;
  margin: 0 20px;
  padding: 0 10px;
  border-radius: 20px;
  border: 0;
}

.gtco-testimonials .card h5 span {
  font-size: 14px;
  /* color: #666; */
  color: #8898aa!important;

}
.gtco-testimonials .card p {
  font-size: 16px;
  color: #555;
  padding-bottom: 15px;
  text-align: center;
}
.card-subtitle, .card-text:last-child {
  margin-bottom: 0;
}


.counter.style2 .title {
  padding-right: 10px;
  font-size: 20px;
}
.color-0dc9ac {
  color: #0dc9ac;
}
input::-webkit-calendar-picker-indicator{
  display: none;
}

input[type="date"]::-webkit-input-placeholder{ 
  visibility: hidden !important;
}